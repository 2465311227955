.container{
  margin: 0 auto;
  height: 160px;
  width: 380px;
 
  
}

.stagebox{
  margin: 0 auto;
  display: flex;
  gap: 10px; /* Adjust the gap between columns as needed */
  background-image: url('../assets/stage.png');
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
          
}

.winner{
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: -30px auto;
}

.crownimg{
  margin: -30px 10px ;
  width: 60px;
  height: 60px;
  position: relative;
  z-index: 0;
  
}
#top{
  margin-top: -30px;
}

.crownimg img{
  width: 100%;
  height: 100%;
}

.positionimg{
  margin: -10px auto;
  width: 90px;
  height: 30px;
}

.positionimg img{
  height: 100%;
width: 100%;
object-fit: contain;
}

.idimg{
  margin: 0 auto;
  width: 70px;
  height: 70px;
}
.idimg img{
  height: 100%;
width: 100%;
border-radius: 50%;
object-fit: cover;
}

.name{

  margin: 0 auto;
  color:rgb(255, 255, 255);
  padding: 15px 10px 5px 10px;
  font-size: 0.9em;
  font-weight: bold;
}

.price , .win {
  margin: 0 auto;
  padding: 5px 10px 5px 10px;
  color: white;
  background: linear-gradient(#007aff,  #73A6DC);
  font-size: 0.9em;
  border: 1px solid rgba(255, 126, 6, 0.527);
  border-radius: 10%;

}

.star {
  font-size: 25px;
  margin: 0 auto;
  color: #fbff00; /* Yellow color */
  text-shadow: 
    0 0 5px #ffd900b2, /* Yellow glow */
    0 0 10px #ffd900b2, /* Yellow glow with higher intensity */
    0 0 15px #ffd9009c, /* Yellow glow with higher intensity */
    0 0 20px #ffd9009c, /* Yellow glow with higher intensity */
    0 0 25px #ffd9009f, /* Yellow glow with higher intensity */
    0 0 30px #ffd90091, /* Yellow glow with higher intensity */
    0 0 35px #ffd90079; /* Yellow glow with higher intensity */
}

.runnerup{
  width: 100%;
}
.fourfive{
  background:#73A6DC;
  width: 95%;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 10px;
 margin: 10px 0;
 justify-content: space-between;
  box-shadow: 0px 10px 15px rgba(0,0,0,0.1);
 
  
}
.img{
  
  
  width: 3.5em;
  height: 3.5em;
  border-radius: 50%;
}

.img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.position{
  font-size: 2em;
  font-weight: 700;
  padding: 0px 10px;
  color: rgb(160, 160, 160);
  border-right: 2px solid rgba(128, 128, 128, 0.527);
  padding-right: 0.5em;
}
.win{
  margin: 10px 10px;
width: max-content;  
height: 80%;
}

.win span{
  align-content: center;
  font-size: 15px;
  font-weight: 400;
}

.runner-up-name{
  width: 50%;color: gray;
  font-size: large;
}