@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

.wrapper{
    overflow-y: hidden;
}
.wrapper .outer{
  display: flex;
  align-items: center;
  justify-content: center;

}
.wrapper .card{

  background: #73A6DC;
  width: 380px;
  display: flex;
  align-items: center;
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  justify-content: space-between;
  border-radius: 20px;
  box-shadow: 0px 10px 15px rgba(0,0,0,0.1);
  animation: animate 15s ease-in-out infinite;
  animation-delay: calc(3s * var(--delay));
}

.wrapper .card:last-child{
  animation-delay: calc(-3s * var(--delay));
}
@keyframes animate {
  0%{
    opacity: 0;
    transform: translateY(-150%) scale(0.9);
  }
  5%, 20%{
    opacity: 1;
    transform: translateY(-100%) scale(0.9);
  }
  25%, 40%{
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0%) scale(0.9);
  }
  45%, 60%{
    opacity: 1;
    transform: translateY(100%) scale(0.9);
  }
  65%, 100%{
    opacity: 0;
    transform: translateY(150%) scale(0.9);
  }
}
.card .content{
    width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.wrapper .card .img{
  height: 70px;
  width: 70px;
  background: transparent;
  border-radius: 50%;
  padding: 0px;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
}

.gameimg{
    height: 60px;
    width: 90px;

}
.gameimg img{
    margin: 0 auto;
    height: 100%;
  width: 90%;
  border-radius: 5%;
  object-fit: cover;
  
}

.card .img img{
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.details span{
  font-weight: 600;
  font-size: 13px;
}
.card a{
  text-decoration: none;
  padding: 7px 18px;
  border-radius: 25px;
  color: #fff;
  background: linear-gradient(to bottom, #bea2e7 0%, #86b7e7 100%);
  transition: all 0.3s ease;
}
.card a:hover{
  transform: scale(0.94);
}
.amount{
    font-size: 13px;
}

.winamt{
    font-size: 13px;
}