.button {
  
  padding: 10px;
  margin: 5px;
  background-color: RGB(25,118,210);
  color: white;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

.button1 {
  
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 5px;
  background-color: RGB(25,118,210);
  color: white;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

.button.active {
  background-color: #3f51b5;
  color: white;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.message-enter {
  opacity: 0;
  transform: translateY(100%);
}

.message-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 500ms, transform 500ms;
}

.message-exit {
  opacity: 1;
}

.message-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 500ms, transform 500ms;
}
